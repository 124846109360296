import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import imgLoading from './Assets/img/gif-loader.gif'

import { Context } from './Context/AuthContext'

import Home from './pages/Home'
import Fipan from './pages/Fipan'
import Login from './pages/Login'

const Routes = () => {

	const { loading } = useContext(Context)

	if (loading) {
		return (
			<div className='loading fullwidth'>
				<img src={imgLoading} alt="" />
				<p>Carregando...</p>
			</div>
		)
	}

	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/fipan" component={Fipan} />
			<Route exact path="/login" component={Login} />
		</Switch>
	)
}

export default Routes
