import React from 'react';
import './styles.css';

export default function RestScreen() {
  return (
    <iframe
      src="restScreen.html"
      title="Rest Screen"
      className="iframe"
      allowFullScreen
      sandbox="allow-scripts allow-same-origin"
    />
  );
}
