import React, { useContext, useState } from 'react'

import { Context } from '../../Context/AuthContext'


import logoNegative from '../../Assets/img/logo-positivo.png'
import show from '../../Assets/img/show.png'
import hidden from '../../Assets/img/hiddenn.png'


const Login = () => {

	const { user, setUser, handleLogin, messageError } = useContext(Context)

	const [showPassword, setShowPassword] = useState(false)

	return (
    <div className="container_app">
      <form onSubmit={handleLogin}>
        <div className="form">
          <div className="imgLogin">
            <img src={logoNegative} alt="" />
          </div>

          <div className="groupForm">
            <label htmlFor="login">Login</label>
            <input
              id="login"
              name="login"
              type="text"
              value={user.user}
              onChange={(e) => setUser({ ...user, user: e.target.value })}
            />
          </div>
          <div className="groupForm">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />

            <img
              src={showPassword ? show : hidden}
              onClick={() => setShowPassword(!showPassword)}
              alt=""
            />
          </div>
          <div className={`${messageError ? "error" : ""}`}>
            <span>{messageError && messageError}</span>
          </div>
          <div className="btn_submit">
            <button type="submit">Entrar</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login
