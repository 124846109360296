import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { api } from "../../services/api";
import { Howl } from "howler";

import bipSoundUrl from "../../Assets/sound/bip.mp3";
// import RestScreen from '../../components/RestScreen/RestScreen';
import Loading from "../../components/Loading/Loading";
import Product from "../../components/Product/Product";
import NotFound from "../../components/Product/NotFound/NotFound";

import LOGO from "../../Assets/img/logo-branco.88a32277.png";

const Home = () => {
  const [ean, setEan] = useState("");
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [product, setProduct] = useState({
    name: "",
    price: "",
    priceOffer: "",
    fideliPrice: "",
    image: "",
    active: 0,
  });
  let timeoutRef = null
  const inputRef = useRef(null);

  const bipSound = useMemo(() => new Howl({
    src: [bipSoundUrl],
  }), []);

  const getEan = useCallback(
    async () => {
      setLoading(true);
      bipSound.play()

      try {
        const { data: vtex } = await api.post(
          "product_vtex",
          { ean },
          { signal: new AbortController().signal }
        );

        if (vtex.Error) {
          setNotFound(true);
          setLoading(false);
          setEan("");
          inputRef.current.focus();
          resetTimeout()
          return;
        }

        setProduct({
          name: vtex.description,
          price: vtex.priceNormal,
          priceOffer: vtex.priceOffer,
          fideliPrice: vtex.priceFidelidade,
          image: vtex.urlImage.ImageUrl,
          active: 1,
        });
        setNotFound(false);
        setLoading(false);
        setEan("");
        resetTimeout()
      } catch (error) {
        if (error.name === "AbortError") {
          return;
        }

        setNotFound(true);
        setLoading(false);
        setEan("");
        inputRef.current.focus();
        resetTimeout()
      }
    },
    [ean]
  );

  const resetTimeout = () => {
    timeoutRef = setTimeout(() => {
      setProduct({
        name: "",
        price: "",
        priceOffer: "",
        fideliPrice: "",
        image: "",
        active: 0,
      });
      setNotFound(false);
      focusInput();
      clearTimeout(timeoutRef);
    }, 12000);
  };

  const focusInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (loading && !ean) {
      clearTimeout(timeoutRef);
    }

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [ean, loading]);

  useEffect(() => {
    focusInput();
  }, []);

  return (
    <div className="container_app">
      <input
        ref={inputRef}
        autoComplete="off"
        id="inputEan"
        type="text"
        inputMode="numeric"
        value={ean}
        name="ean"
        onChange={(e) => {
          if (!loading) {
            setEan(e.target.value);
          }
        }}
        onKeyDown={(e) => {
          if(e.keyCode === 13 && !loading && ean) {
            getEan()
          }
        }}
      />

      {loading && <Loading />}

      {!notFound && product.active === 0 && <img src={LOGO} alt={product.name} width={750} height={250} />}

      {!notFound && product.active === 1 && <Product product={product} />}

      {notFound && <NotFound />}
    </div>
  );
};

export default Home;
